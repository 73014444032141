import { Box } from "@outschool/backpack";
import { useTranslation } from "@outschool/localization";
import { Component } from "@outschool/ownership-areas";
import {
  addParamsToUrl,
  browseRootPath,
  loginPath,
  searchPath,
  teachPath,
} from "@outschool/routes";
import {
  ContinueWithApple,
  ContinueWithGoogle,
  ContinueWithKakao,
  ContinueWithLine,
  EmailSignupWithinModalForm,
  LoginUserFeedback,
  getPostLoginPath,
  useAuthError,
  useCanShowGoogleAuth,
  useRefreshSessionToken,
  useShouldShowKakao,
  useShouldShowLine,
  useTokenContext,
} from "@outschool/ui-auth";
import { AccountDisclaimer } from "@outschool/ui-components-shared";
import {
  TeacherRegistrationLink,
  switchToLearnerModeWithToken,
} from "@outschool/ui-components-website";
import { useLinkComponent } from "@outschool/ui-utils";
import { TFunction, Trans } from "next-i18next";
import { useRouter } from "next/router";
import React, { useState } from "react";

import {
  APPLE_SIGNIN_CLIENT_ID,
  GOOGLE_CLIENT_ID,
  isReviewApp,
} from "../../../Env";
import {
  useLoginWithApple,
  useLoginWithRedirect,
} from "../hooks/Authentication";

interface SignupFormProps {
  onRedirect?: (e: any) => void;
  promptLogin?: boolean;
  isTeacherFlow?: boolean;
  trackingParameters?: { [key: string]: string };
  trackingUniqueId?: string;
  signupParams?: { [key: string]: string | boolean };
  joinFromNavBar?: boolean;
  setIsRedirecting?: (isRedirecting: boolean) => void;
}

export default function SignupForm({
  onRedirect,
  promptLogin,
  isTeacherFlow,
  trackingParameters,
  trackingUniqueId,
  signupParams,
  joinFromNavBar,
  setIsRedirecting = () => {},
}: SignupFormProps) {
  const Link = useLinkComponent();
  const { t } = useTranslation("ssr-client\\components\\SignupForm");
  const postLoginPath = getPostLoginPath();
  const [disableSignupButtons, setDisableSignupButtons] = useState(false);

  // Conditionally show email signup form within modal
  const [showEmailSignupForm, setShowEmailSignupForm] = useState(false);
  return (
    <Box
      sx={{
        display: "grid",
        gap: "1em",
        width: "100%",
      }}
    >
      <SignupButtons
        isTeacherFlow={isTeacherFlow}
        trackingParameters={trackingParameters}
        trackingUniqueId={trackingUniqueId}
        signupParams={signupParams}
        setDisableSignupButtons={setDisableSignupButtons}
        disableSignupButtons={disableSignupButtons}
        setShowEmailSignupForm={setShowEmailSignupForm}
        showEmailSignupForm={showEmailSignupForm}
        setIsRedirecting={setIsRedirecting}
        postLoginPath={postLoginPath}
      />
      {promptLogin && joinFromNavBar ? (
        <TeacherRegistrationLink
          onRedirect={onRedirect}
          postLoginPath={postLoginPath}
          trackingUniqueId={trackingUniqueId}
        />
      ) : promptLogin ? (
        <Trans t={t as TFunction}>
          <Box
            sx={{
              justifySelf: "center",
            }}
          >
            Already have an account?{" "}
            <Link
              data-test-id="login-link"
              onClick={onRedirect}
              to={addParamsToUrl(loginPath(), {
                postLoginPath: postLoginPath,
              })}
              trackingName="login"
              trackingUniqueId={trackingUniqueId}
            >
              Log in
            </Link>
          </Box>
        </Trans>
      ) : null}
      <AccountDisclaimer
        isSignup={true}
        showEmailSignupWithinModal={showEmailSignupForm}
      />
    </Box>
  );
}

function SignupButtons({
  isTeacherFlow,
  trackingParameters,
  trackingUniqueId,
  signupParams,
  postLoginPath,
  setDisableSignupButtons,
  disableSignupButtons,
  setShowEmailSignupForm,
  showEmailSignupForm,
  setIsRedirecting,
}) {
  const { setTokens } = useTokenContext();
  const [userFeedbackMessage, setUserFeedbackMessage] =
    React.useState<React.ReactNode>(null);
  const canShowGoogleAuth = useCanShowGoogleAuth();

  const signupToSearchOrPostLoginPath =
    postLoginPath === browseRootPath() ? searchPath() : postLoginPath;

  const createParams = {
    isGiftCardSignup: signupParams?.isGiftCardSignup,
    isLeader: isTeacherFlow,
    trackingParameters,
  };

  const handleAuthError = React.useCallback(
    (error, message: React.ReactNode = "Sorry that didn't work, try again") => {
      OsPlatform.captureError(error, {
        tags: { component: Component.UserAccountManagement },
      });
      setUserFeedbackMessage(message);
    },
    [setUserFeedbackMessage]
  );

  const generateLoginError = useAuthError();

  const handleLoginSuccess = React.useCallback(
    async ({ sessionToken, refreshToken, isLearnerTransfer }) => {
      if (!isLearnerTransfer) {
        setTokens(sessionToken, refreshToken);

        const redirectPath = isTeacherFlow
          ? teachPath()
          : signupToSearchOrPostLoginPath;
        window.history.pushState({}, "", redirectPath);
        window.location.reload();
      } else {
        switchToLearnerModeWithToken({
          isReviewApp,
          transferToken: sessionToken,
          isLearnerLogin: true,
        });
      }
    },
    [isTeacherFlow, setTokens, signupToSearchOrPostLoginPath]
  );

  const handleLoginError = React.useCallback(
    error => {
      const errorObject = new Error(error);
      handleAuthError(errorObject, generateLoginError(error));
    },
    [handleAuthError, generateLoginError]
  );

  const handleAppleLogin = useLoginWithApple(
    handleLoginSuccess,
    handleLoginError,
    createParams
  );

  const setAuthStrategy = useLoginWithRedirect(handleLoginError, createParams);

  const showKakao = useShouldShowKakao();
  const showLine = useShouldShowLine();

  const router = useRouter();
  const searchParams = router.query;
  const refreshSessionToken = useRefreshSessionToken();

  return (
    <React.Fragment>
      <LoginUserFeedback
        userFeedbackMessage={userFeedbackMessage}
        isError={true}
      />
      {canShowGoogleAuth && (
        <ContinueWithGoogle
          trackingName="signup-with-google"
          setAuthStrategy={setAuthStrategy}
          clientId={GOOGLE_CLIENT_ID!}
          disableWhileLoading={disableSignupButtons}
        />
      )}

      {showLine && (
        <ContinueWithLine
          isLearnerApp={false}
          setAuthStrategy={setAuthStrategy}
          trackingName="signup-with-line"
          disableWhileLoading={disableSignupButtons}
        />
      )}

      {showKakao && (
        <ContinueWithKakao
          isLearnerApp={false}
          setAuthStrategy={setAuthStrategy}
          trackingName="signup-with-kakao"
          disableWhileLoading={disableSignupButtons}
        />
      )}

      <ContinueWithApple
        onSuccess={handleAppleLogin}
        onError={handleAuthError}
        trackingName="signup-with-apple"
        clientId={APPLE_SIGNIN_CLIENT_ID!}
        disableWhileLoading={disableSignupButtons}
      />
      <EmailSignupWithinModalForm
        showEmailSignupForm={showEmailSignupForm}
        setShowEmailSignupForm={setShowEmailSignupForm}
        setDisableSignupButtons={setDisableSignupButtons}
        searchParams={searchParams}
        trackingUniqueId={trackingUniqueId}
        onUserLoggedIn={async (sessionToken: string, refreshToken: string) => {
          await refreshSessionToken();
          setTokens(sessionToken, refreshToken);
          const redirectPath = signupToSearchOrPostLoginPath;
          window.history.pushState({}, "", redirectPath);
          window.location.reload();
        }}
        onUserRefresh={() => {
          router.reload();
        }}
        isTeacherFlow={isTeacherFlow}
        setIsRedirecting={setIsRedirecting}
      />
    </React.Fragment>
  );
}
